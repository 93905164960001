import axios, { AxiosInstance } from "axios";

export const createService = (baseURL?: string, headers?: object): AxiosInstance => {
  return axios.create({
    baseURL,
    headers: {
      "Accept-Language": "en-US",
      "Content-type": "application/json",
      "Cache-Control": "no-cache",
      ...headers,
    },
    withCredentials: true,
    timeout: 30000,
  });
};

export default createService(process.env.REACT_APP_MYRIAVERSE_API);
