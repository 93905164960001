import Nodes from "app/components/Nodes";
import LayoutAuth from "app/layout/LayoutAuth";

const NodesPage = () => {
  return (
    <LayoutAuth>
      <Nodes />
    </LayoutAuth>
  );
};

export default NodesPage;
