import { toast } from "react-toastify";
import Web3 from "web3";
import { ethers } from "ethers";
import { getModuleFactory } from "app/service/myriaCodeSDK";
import { TokenType, TransactionData, BulkTransferTokenResponse, TransactionPagingDetails } from "myria-core-sdk";
import { APIResponseType } from "myria-core-sdk/dist/types/src/types/APIResponseType";
import BN from "bn.js";

export const messageInvalidSender = "Sender Wallet Address Invalid";
export const messageInvalidWalletAddress = "Receiver Wallet Address Invalid";
export const messageInvalidToken = "Amount of Tokens is Invalid";
export const messageInvalidTokenId = "Token ID is invalid, it must be an integer";
export const messageInvalidSmartContract = " Contract address is invalid.";
export const regexToken = /^[0-9]{1,20}$/;
export const requireField = "Require Field";
export const senderReceiverInvalid = "Sender and receiver wallet should be different";
export const messageGroupRequestId = "Group Request ID should be in uuid V4 format";
export const messageRequestTimeOut =
  "The request has been timeout, but the data could be capture potentially in our BE server already. Kindly check with the query transaction lists page with groupRequestID.";
export const messageErrorSenderInvalidAccount = "There is invalid sender address, it needs to have sender address match with current account.";
export const messageErrorGroupRequestId = "The group request ID must be valid UUID";
export const messageErrorMaxTransaction = "The distribution events limit with 1000 transactions only.";
export const messageErrorMaxAmountTransfer = "The maximum amount for transfer is only 500.000.000 tokens, please try with smaller number";
export const messageUserNoSignature = "Access Denied!";

export enum dataIndexDefault {
  key = "key",
  sender = "sender",
  receiverWalletAddress = "receiverWalletAddress",
  token = "token",
  tokenId = "tokenId",
  status = "status",
  smartContract = "smartContract",
  tokenType = "tokenType",
}

export const titleExcel = {
  [dataIndexDefault.key]: "Index",
  [dataIndexDefault.sender]: "Sender Wallet Address",
  [dataIndexDefault.receiverWalletAddress]: "Receiver Wallet Address",
  [dataIndexDefault.token]: "Amount(Token ERC20)",
  [dataIndexDefault.status]: "Status",
  [dataIndexDefault.tokenId]: "Token ID",
  [dataIndexDefault.smartContract]: "Contract Address",
  [dataIndexDefault.tokenType]: "Token Type",
};

export const maximumAmountTransfer = 500000000; // Maximum as 500 mils of tokens

export const listEmailAdmin = [
  "daniel.lee@myria.com",
  "rafael.solorzano@myria.com",
  "vanessa@myria.com",
  "brendan.duhamel@myria.com",
  "francesco.tavano@myria.com",
  "khang.nguyen@myria.com",
  "maxime.delobel@myria.com",
  "gina.tiriakidou@myria.com",
  "duy.le@myria.com",
  "vulewenlian94@gmail.com",
];

export enum statusBulk {
  completePending = "Pending",
  completeSuccess = "Success",
  failed = "Failed",
  inProgress = "Prepare",
  failedGateWay = "FailedGateway",
}

export const localStorageKeys = {
  starkKey: "STARK_KEY",
  walletAddress: "WALLET_ADDRESS",
};

interface ErrorExternal {
  code: number;
  errorCode: number;
  detail: string;
}

interface ExternalErrors {
  status: string;
  errors: ErrorExternal[];
}
interface ErrorsArray {
  code: number;
  title: string;
  detail: string[] | string;
  errorCode?: number;
  externalErrors?: ExternalErrors;
  internalErrors?: any[];
}

interface ErrorAxiosTransfer {
  status: string;
  errors: ErrorsArray[];
  code?: number;
}

export const parseErrorAxios = (error: any) => {
  const errorMessage = error.message;
  const indexErrorObject = errorMessage.indexOf("{");
  const stringError = errorMessage.substring(indexErrorObject);
  const jsonError: ErrorAxiosTransfer = JSON.parse(stringError);
  return jsonError;
};

// export const myriaTokenAddress = "0xA06116D9E28AF403d6989c45EF8C0b9B971e5E12"; // TESTNET
// export const myriaTokenAddress = "0x36f78617503f6440e83e3fd742c091394ce0c195"; // Unofficial token mainnet
export const myriaTokenAddress = "0xa0ef786bf476fe0810408caba05e536ac800ff86"; // Official Myria Token
export const partnerRefId = "Myria-Internal-System";
export const erc20Description = "Myria-Internal-System-Transfer-MYR-Tokens";
export const nftDescription = "Myria-Internal-System-Transfer-NFT-Tokens";
export const handlerCopy = (text: string) => {
  navigator.clipboard.writeText(text).then(
    function () {
      toast("Copied!", { type: "success" });
    },
    function (err) {
      toast(err, { type: "error" });
    }
  );
};
const QUANTUM = "10000000000";
/**
 * Convert eth to wei
 * @param amount
 * @returns
 */
function convertEthToWei(amount: string): string {
  return ethers.utils.parseEther(String(amount)).toString();
}

export function convertQuantizedAmountToEth(amount: string): string {
  if (!amount || Number(amount) === 0) {
    return "0";
  }
  const amountQuantum = Number(amount) * Number(QUANTUM);
  return Web3.utils.fromWei(String(amountQuantum.toLocaleString("fullwide", { useGrouping: false })));
}

export function convertAmountToQuantizedAmount(amount: number | string): number {
  const wei = convertEthToWei(String(amount));
  const quantizedAmount = Number(new BN(wei, 10).div(new BN(QUANTUM, 10)).toString());
  return quantizedAmount;
}

interface dataStatusRetry {
  key: number;
  sender: string;
  receiverWalletAddress: string;
  token?: string;
  status: statusBulk;
  tokenType: TokenType;
  tokenId?: string;
  smartContract?: string;
}

interface transactionPagingRetry {
  items: dataStatusRetry[];
  lastEvaluatedKey?: TransactionPagingDetails;
}

export const getTransaction = async (groupRequestID?: string, paginationTransaction?: TransactionPagingDetails) => {
  const dataNoGroupRequest: transactionPagingRetry = {
    items: [],
    lastEvaluatedKey: undefined,
  };
  if (!groupRequestID) {
    return dataNoGroupRequest;
  }
  const moduleFactory = await getModuleFactory();
  const transactionManager = moduleFactory.getTransactionManager();
  const data = await transactionManager.getTransactionsByGroupRequestIDAndPartnerRefID(groupRequestID, partnerRefId, paginationTransaction);
  if (data.data.items.length > 0) {
    const handleDataSort = data.data.items.sort((itemA: TransactionData, itemB: TransactionData) => itemA.nonce - itemB.nonce);
    const transactionData: dataStatusRetry[] = handleDataSort.map((item: TransactionData, index) => {
      return {
        key: index,
        sender: item.senderWalletAddress ?? item.starkKey,
        receiverWalletAddress: item.receiverWalletAddress ?? item.receiverPublicKey,
        token: convertQuantizedAmountToEth(item.quantizedAmount),
        tokenId: item.tokenId,
        smartContract: item.tokenAddress,
        status: item.transactionStatus as statusBulk,
        tokenType: item.tokenType,
      };
    });
    const dataRespond: transactionPagingRetry = {
      items: transactionData,
      lastEvaluatedKey: data.data.lastEvaluatedKey,
    };
    return dataRespond;
  } else {
    return dataNoGroupRequest;
  }
};
export const arrayFieldCopy = [dataIndexDefault.sender, dataIndexDefault.receiverWalletAddress, dataIndexDefault.smartContract];
export const timeoutBulkTransfer = 200000;

export const maxTransactionData = 1000;
export const BATCH_THRESHOLD = 1000;
export const QUERY_TRANSACTIONS_INTERVAL = 60000;

export const queryKey = {
  getTransactionAPI: "getTransactionAPI",
};

export const asyncCallWithTimeout = async (
  asyncPromise: Promise<APIResponseType<BulkTransferTokenResponse>>,
  timeLimit: number
): Promise<APIResponseType<BulkTransferTokenResponse>> => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error(messageRequestTimeOut)), timeLimit);
  });
  return Promise.race([asyncPromise, timeoutPromise])
    .then((result: any) => {
      clearTimeout(timeoutHandle);
      return result;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export interface InforEmailRespond {
  email: string;
  hd: string;
}

export const API_KEY = process.env.REACT_APP_MYRIAVERSE_ADMIN_API_KEY;
