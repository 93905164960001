import { listEmailAdmin } from "app/utils/constant";
import React from "react";
import { useHistory } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";

const GOOGLE_CLIENT_AUTH_ID = process.env.REACT_APP_CLIENT_ID_AUTH || "189121918977-t32044hdb4nqidg9t0cmgu9l8s8cgf8h.apps.googleusercontent.com";

export default function LoginPage() {
  const history = useHistory();

  const [login, setLogin] = React.useState(true);

  const detectLogin = async (email: string, hd: string, accessToken: string) => {
    if (hd === "myria.com" || listEmailAdmin.includes(email)) {
      localStorage.setItem("accessToken", accessToken);
      setLogin(true);
      history.push("/collections");
    } else {
      setLogin(false);
    }
  };

  return (
    <div className="pt-[64px] w-full h-full flex items-center justify-center">
      <div className="w-[300px] h-[300px] rounded-[10px] border border-gray-800 flex flex-col justify-around items-center shadow-2xl">
        <div className="flex flex-col items-center">
          <img src="assets/images/google.png" className="mb-3 w-11 h-11" alt="google-img" />
          Sign in with Google
        </div>
        <LoginSocialGoogle
          scope="email profile"
          client_id={GOOGLE_CLIENT_AUTH_ID}
          onReject={(err) => {
            console.log("Rejected: ", err);
          }}
          onResolve={({ provider, data }: any) => {
            detectLogin(data.email, data.hd, data.access_token);
          }}
        >
          <div className="py-[10px] px-5 rounded-md border border-gray-700 flex items-center hover:cursor-pointer hover:bg-gray-400">
            <img src="assets/images/google.png" className="w-6 h-6 mr-3" />
            Google Login
          </div>
        </LoginSocialGoogle>
        {!login ? <div className="text-red-600">Login failed</div> : ""}
      </div>
    </div>
  );
}
