import { Modules } from "app/service/helper";
import { Web3Context, Web3ContextType } from "context/Web3Context";
import React from "react";
import { useParams } from "react-router-dom";
import { EnvTypes, CollectionDetailsResponseData, UpdateCollectionByContractAddressParams } from "myria-core-sdk";
import { Button } from "app/components/Buttons";
import UploadBanner from "app/components/UploadImage/UploadBanner";
import UploadLogo from "app/components/UploadImage/UploadLogo";
import Modal from "app/components/Modal/DataModal";
import { AdminUpdateCollectionInfo } from "types";
import { env } from "config";
import { checkAuthStatus } from "app/utils/pipeline";
import Alert from "app/components/Alert";
import WarningModal from "app/components/Modal/WarningModal";

interface UpdateInfoType {
  name?: string;
  description?: string;
  metadataApiUrl?: string;
  isHot?: number;
}

export default function CollectionByIdPage() {
  const params: any = useParams();

  const { provider, web3Instance }: Web3ContextType = React.useContext(Web3Context);

  const [collectionInfo, setCollectionInfo] = React.useState<CollectionDetailsResponseData>();
  const [showModal, setShowModal] = React.useState(false);
  const [collectionImageUrl, setCollectionImageUrl] = React.useState();
  const [iconUrl, setIconUrl] = React.useState();
  const [loginStatus, setLoginStatus] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [warnigMessage, setWarningMessage] = React.useState("");

  React.useEffect(() => {
    getCollectionById(env, params.id);
  }, []);

  React.useEffect(() => {
    if (params?.id) {
      getCollectionById(env, params.id);
    }
  }, [provider, web3Instance]);

  const getCollectionById = async (env: EnvTypes, id: number) => {
    const modules = new Modules(env);

    setLoading(true);

    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      try {
        const authStatus = await checkAuthStatus(accessToken);

        if (authStatus) {
          const collection: any = await modules.getCollectionById(id);
          if (collection) {
            setCollectionInfo(collection);
          }
          setLoading(false);
        } else {
          setLoginStatus(false);
        }
      } catch (err) {
        setLoginStatus(false);
      }
      setLoading(false);
    } else {
      setLoginStatus(false);
    }

    setLoading(false);
  };

  const onClose = () => {
    setWarning(false);
  };

  const handleUpdateButtonClick = () => {
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const updateCollection = async () => {
    setLoading(true);
    if (collectionInfo?.contractAddress) {
      let updateParams: UpdateCollectionByContractAddressParams;

      updateParams = {
        contractAddress: collectionInfo.contractAddress,
        name: collectionInfo.name,
        starkKey: collectionInfo.starkKey,
        collectionImageUrl: collectionImageUrl,
        description: collectionInfo.description,
        iconUrl: iconUrl,
      };

      const module = new Modules(env);

      const updateResult: CollectionDetailsResponseData | undefined = await module.updateCollectionByContractAddress(updateParams);
      if (typeof collectionInfo.isHot !== "undefined") {
        const metaUpdateParams: AdminUpdateCollectionInfo = {
          isHot: String(collectionInfo.isHot) === "true" ? true : false,
        };

        const result = await module.updateCollectionMetadataApiUrl(collectionInfo.contractAddress, metaUpdateParams);
        setCollectionInfo(result.data.data);
        setLoading(false);
      } else {
        setCollectionInfo(updateResult);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  return loginStatus ? (
    <div className="text-white">
      {collectionInfo && (
        <div className="w-full min-h-screen">
          <div className="w-full md:h-[327px] h-[172px]">
            <UploadBanner
              icon={false}
              imgUrl={collectionInfo.collectionImageUrl}
              setUploadFileUrl={setCollectionImageUrl}
              setWarningMessage={setWarningMessage}
              setWarning={setWarning}
            ></UploadBanner>
          </div>

          <div className="w-full h-fit flex md:items-center items-start md:flex-row flex-col justify-between ">
            <div className="md:ml-16 ml-0 relative z-10 w-28 h-28 overflow-hidden ring-4 ring-backgroundColor1 rounded-full -translate-y-2/4">
              <UploadLogo
                icon={true}
                imgUrl={collectionInfo.iconUrl}
                setUploadFileUrl={setIconUrl}
                setWarningMessage={setWarningMessage}
                setWarning={setWarning}
              ></UploadLogo>
            </div>
            <div className="md:mt-6 mt-0 flex">
              <Button className={"w-56"} content={"Update Collection Details"} onClick={() => handleUpdateButtonClick()}></Button>
              <Button className={"!w-56"} content={"Save changes"} loading={String(loading)} onClick={() => updateCollection()}></Button>
            </div>
          </div>
          <div className="flex md:flex-col flex-row mt-4">
            <div className="md:ml-16 ml-0">
              <div className="text-white font-bold text-2xl mt-1">{collectionInfo.project.name}</div>
              <div className="flex mt-1">
                <div className="text-header-button-hoverTextColor text-xl">Created By&nbsp;</div>
                <div className="text-white text-xl">{collectionInfo.project.companyName}</div>
              </div>
              <div className="text-white text-xl mt-1">{collectionInfo.name}</div>
              <div className="text-white text-x1 mt-1">{collectionInfo.isHot ? "Hot Collection" : "Not Hot"}</div>
              <div className="flex md:flex-row flex-col md:items-center items-start w-full">
                <div className="text-header-button-hoverTextColor text-2xl mt-1">Description:&nbsp;&nbsp;&nbsp;</div>
                <div className="text-white text-xl mt-1">{collectionInfo.description}</div>
              </div>
              <div className="flex md:flex-row flex-col md:items-center items-start w-full">
                <div className="text-header-button-hoverTextColor text-2xl mt-1">Owner of this item:&nbsp;&nbsp;&nbsp;</div>
                <div className="text-white text-xl mt-1">{collectionInfo.ownerPublicKey}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showModal}
        onClose={modalClose}
        collectionInfo={collectionInfo}
        setUpdateCollection={setCollectionInfo}
        collectionValue={collectionInfo}
      ></Modal>
      <WarningModal warningMessage={warnigMessage} showModal={warning} onClose={onClose} />
    </div>
  ) : (
    <Alert></Alert>
  );
}
