/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from "react-helmet-async";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./layout/Layout";
import LoginPage from "./pages/LoginPage";
import CollectionsPage from "./pages/CollectionsPage";
import CollectionByIdPage from "./pages/CollectionByIdPage";
import DistributesAirdrop from "./pages/DistributesAirdrop";
import DistributesNFT from "./pages/DistributesNFT";
import RetryDistribute from "./pages/RetryDistributes";
import QueryTransactionPage from "./pages/QueryTransactionPage";
import NodesPage from "./pages/Nodes";

export function App() {
  const { i18n } = useTranslation();
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={"/login"} component={LoginPage} />
          <PrivateRoute exact path={"/collections/"} component={CollectionsPage} />
          <PrivateRoute exact path={"/collections/:id"} component={CollectionByIdPage} />
          <Switch>
            <PrivateRoute exact path={"/admin-tools/distribute-airdrop"} component={DistributesAirdrop} />
            <PrivateRoute exact path={"/admin-tools/distribute-nft"} component={DistributesNFT} />
            <PrivateRoute exact path={"/admin-tools/query-transaction"} component={QueryTransactionPage} />
            <PrivateRoute exact path={"/admin-tools/query-transaction/:paramGroupRequestID"} component={QueryTransactionPage} />
            <PrivateRoute exact path={"/admin-tools/retryDistribute/:groupRequestID"} component={RetryDistribute} />
            <PrivateRoute exact path={"/admin-tools/nodes"} component={NodesPage} />
          </Switch>
          <Redirect to="/collections"></Redirect>
        </Switch>
      </Layout>
    </Router>
  );
}
